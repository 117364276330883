<template>
  <div ref="echarts" :style="{ width: '100%', height: '100%', }"></div>
</template>
<script>
//  饼图
const echarts = require('echarts');
const $ = require('@/assets/js/jquery.js')
export default {
  props: {
    // 定时器重新生成时间
    setInterval_time: {
      type: Number,
      default: 12000
    },
    // 引导线显示的最小比例
    minShowLabelAngle: {
      type: Number,
      default: 10
    },
    // 饼图radius属性
    radius: {
      default: () => {
        return ['40%', '60%']
      }
    },
    // 饼图center属性
    center: {
      default: () => {
        return ['50%', '50%']
      }
    },
    //字体颜色
    textcolor: {
      default: 'auto'
    },
    // 饼图颜色组
    colors: {
      default: () => {
        return [
          '#E2D24E',
          '#0BB189',
          '#F6797D',
          '#30A2F7',
          '#3047F7',
          '#A0F1F1',
          '#E3AD61'
        ]
      }
    },
    // 饼图引导线
    labelLine: {
      default: () => {
        return {
          length: 20,
          length2: 50,
          maxSurfaceAngle: 40
        }
      }
    },
    // 单位
    unit: {
      type: String,
      default: '条'
    },
    // 图例显示项
    legend: {
      default: false
    },
    // 图例标题
    title: {
      default: false
    },
    series: {
      default: false
    },
    // 是否展示成南丁格尔图，通过半径区分数据大小
    // 'radius' 扇区圆心角展现数据的百分比，半径展现数据的大小。
    // 'area' 所有扇区圆心角相同，仅通过半径展现数据大小。
    roseType: {
      // type: String,
      default: false
    },
    itemStyle: {
      default: false
    }
  },
  data() {
    return {
      // 定时器对象
      setInterval_echart: {},
      data: [],
      //  [
      //   { value: 1048, name: "劳动合同" },
      //   { value: 735, name: "财务合同" },
      //   { value: 580, name: "租赁合同" },
      //   { value: 484, name: "开发合同" },
      //   { value: 484, name: "劳务合同" },
      // ],
    };
  },
  methods: {
    // echarts 展示方法
    show(data) {
      this.data = data
      this.get_echart(echarts.init(this.$refs.echarts), data)
    },
    get_echart(echart, data) {
      let that = this
      let option = {
        color: this.colors,
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}' + that.unit + '<br>占比:{d}%',
        },
        series: [
          {
            type: 'pie',
            radius: this.radius,
            center: this.center,
            itemStyle: this.itemStyle,
            // 最小多少显示
            minShowLabelAngle: this.minShowLabelAngle,
            label: {
              show: false,
              padding: [0, 70],
              alignTo: 'edge',
              formatter: '{name|{b}: {c}(' + that.unit + ')}\n{bfb|{d}%}',
              edgeDistance: 20,
              lineHeight: 20,
              color: this.textcolor,
              rich: {
                time: {
                  fontSize: 10,
                  color: '#999'
                }
              }
            },
            labelLine: this.labelLine,
            data: data
          },
        ]
      }
      if (this.series) {
        option.series = []
        this.series.forEach(item => {
          option.series.push({ ...item, data: data })
        });
      }
      // 标题配置
      if (this.title) {
        option.title = this.title
        if (this.title[0].texttype === 1) {
          option.title[0].text = data[0].value + data[1].value
        }
      }
      // 图例配置
      if (this.legend) {
        option.legend = this.legend
        option.legend.data = data
        // formattertype 1 默认 2名称 百分百 数量
        if (this.legend.formattertype === 2) {
          option.legend.formatter = function (name) {
            let total = 0;
            let tarValue = 0;
            let value = 0;
            let shortName = null
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                shortName = data[i].shortName
                tarValue = data[i].value;
                value = data[i].value;
              }
            }
            let p = total === 0 ? 0 : ((tarValue / total) * 100).toFixed(1);
            return [
              "{name|" +
              echarts.format.truncateText(
                shortName ? shortName : name,
                80,
                "14px Microsoft Yahei",
                "…"//如果宽度超过80会出现...
              ) +
              "}",
              "{account|" + p + "%}",
              "{value|" + value + "}"  //a、b、x、跟下面的rich对应
            ].join(" ");
          }
        } else if (this.legend.formattertype === 3) {
          option.legend.formatter = function (name) {
            let total = 0;
            let tarValue = 0;
            let value = 0;
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                tarValue = data[i].value;
                value = data[i].value;
              }
            }
            let p = total === 0 ? 0 : ((tarValue / total) * 100).toFixed(1);
            return [
              "{name|" +
              echarts.format.truncateText(
                name,
                80,
                "14px Microsoft Yahei",
                "…"//如果宽度超过80会出现...
              ) +
              "}",
              "{value|" + value + "}",  //a、b、x、跟下面的rich对应
              "{account|" + p + "%}"
            ].join(" ");
          }
        }
      }
      //南丁格尔图是否展示
      if (this.roseType) {
        option.series[0].roseType = this.roseType
      }
      echart.setOption(option, true)
      window.addEventListener("resize", function () {
        echart.resize();
      });
    },
  }
};
</script>