<template>
  <pieChart ref="pieChartRef" :labelLine=state.labelLine :legend='state.legend' :series='state.series'></pieChart>
</template>

<script setup>
import { reactive, ref, unref } from "vue";
import pieChart from "@/components/echarts/PieChart.vue";
const pieChartRef=ref()
const state = reactive({
  legend: {
    left: "center",
    y: 'bottom',
    icon: "circle",
  },
  series: [
    {
      name: "Access From",
      type: "pie",
      radius: ["38%", "60%"],
      center:['54%', '42%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 0,
        borderColor: "#fff",
        borderWidth: 2,
      },
      label: {
        show: false,
        // position: "center",
      },
      emphasis: {
        label: {
          show: false,
          fontSize: 40,
          fontWeight: "bold",
        },
      },
    },
  ],
  labelLine:{
    show:false
  },
});
const setpieChart=(data)=>{
  unref(pieChartRef).show(data)
}
defineExpose({
  setpieChart
})
</script>

<style lang="scss" scoped>
</style>